import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import {
  hairSalonHero,
  hairSalonSubHero,
  hairSalonWhiteGlove,
  hairSalonLargeFeatureA,
  hairSalonTrusted,
  hairSalonMultiSection,
  hairSalonCTA,
  hairSalonTestimonials,
  // hairSalonArticles,
  // hairSalonFaqs,
  hairCar,
} from "../../../data/hair-salon-data";
import { hbBTSlides, heroSuccess } from "../../../data/success-stories-data";
// import NoVisualsCustom from "../../../components/NoVisuals/NoVisualsCustom";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";

import heroBg from "../../../images/hero-images/hair-salon-white-glove.png";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);

const whiteGloveImport = "hair-salon-white-glove.png";

const Index = () => {
  return (
    <Layout>
      <SEO
        title="Hair Salon & Spa Point of Sale (POS) Software | SpotOn "
        description="SpotOn's Hair Salon & Spa POS software offers payment processing, appointment booking, & marketing solutions to grow your business. Get a demo!"
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/health-and-beauty/hair-salons/"
      />
      <BHero sectionData={hairSalonHero} heroBg="hair-salon-white-glove.png" />
      <NoVisualsCustom sectionData={hairSalonSubHero} complex />
      <WhiteGlove
        sectionData={hairSalonWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={hairSalonLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={hairSalonTrusted} />
      <LargeFeatures
        sectionData={hairSalonMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={hairSalonCTA} />
      <SuccessStories sectionData={heroSuccess} slides={hbBTSlides} />
      <TestmonialReviews sectionData={hairSalonTestimonials} />
      {/*      <Articles
        sectionData={hairSalonArticles}
        blogEntries={tempData}
        cornerStone={false}
      /> */}
      <BusinessTypes sectionData={hairCar} />
      {/* <Faqs sectionData={hairSalonFaqs} /> */}
    </Layout>
  );
};

export default Index;
